<template>
  <multiselect
    v-model="internalItems"
    tag-placeholder="Dieses Schlagwort erstellen"
    placeholder="Durchsuchen oder neues Schlagwort erstellen"
    label="name"
    track-by="id"
    :options="options"
    :multiple="true"
    :taggable="true"
    select-label="Hinzufügen"
    deselect-label="Entfernen"
    selected-label="Ausgewählt"
    @tag="addTag"
    @remove="removeTag"
    @select="selectTag"
  ></multiselect>
</template>

<script>
import Vue from "vue"
import Multiselect from "vue-multiselect"

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    regionId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      items: [],
      internalItems: []
    }
  },
  watch: {
    loading(value) {
      if (value === false) {
        this.internalItems = JSON.parse(JSON.stringify(this.value))
        this.items = JSON.parse(JSON.stringify(this.value))
      }
    }
  },
  async created() {},
  methods: {
    addTag: function(value) {
      let that = this
      value.split(",").map(item => {
        let result = that.options.find(
          t => t.name.toLowerCase() === item.trim().toLowerCase()
        )
        if (result) {
          let tag = {
            id: result.id,
            name: result.name
          }
          this.internalItems.push(tag)
          this.items.push(tag)
        } else {
          if (item.trim() !== "") {
            let tag = {
              name: item.trim()
            }
            if (this.regionId) {
              tag.region_id = this.regionId
            }
            let iTag = {
              name: item.trim(),
              id: item.trim()
            }

            this.internalItems.push(iTag)
            this.items.push(tag)
          }
        }
        this.$emit("input", this.items)
      })
    },
    removeTag: function(value) {
      let tag = this.items.find(t => t.name === value.name)
      Vue.set(tag, "_destroy", true)
      this.$emit("input", this.items)
    },
    selectTag: function(value) {
      this.items.push(value)
      this.$emit("input", this.items)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
